'use strict';

import "core-js/stable";
import "regenerator-runtime/runtime";

const TRANSITION_END = 'webkitTransitionEnd transitionend';
// const BREAK_POINT = 960;
import BREAK_POINT from './global';


require("picturefill");
import 'jquery-inview'
import 'objectFitPolyfill';
import "jquery.scrollbar";
import 'slick-carousel';
import anime from 'animejs'
import Rellax from 'rellax';
import ScrollHelper from './scrollhelper.js';
import ResizeHelper from './resize.js';
import SmoothScroll from './smoothScroll.js';
import Device from './device.js';
import 'url-polyfill';

const $body = $('body');
const $window = $(window);



window.addEventListener('load', () => {

  $('#about-mv').css('visibility', 'visible');

  if(!document.querySelector('#about-mv')) return;

    anime({
      targets: '#about-mv .circle-body',
      opacity: [0, 1],
      // round: 1,
      elasticity: 0,
      // delay: function(el, i) { return i * 600 },
      delay: 800,
      duration: 1400,
      easing: 'easeOutCubic',
      loop: false
    })

    // function animateAbout(){

    //
    const fadeText = anime({
      targets: '#about-mv .text',
      opacity: [0, 1],
      autoplay: false,
      elasticity: 0,
      delay: 3500,
      duration: 600,
      easing: 'easeOutCubic',
      loop: false
    })
    //
    const tl = anime.timeline({
      autoplay: false,
    });
        tl
          // line animation
          .add({
            targets: '.about-circle_line',
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeOutCubic',
            duration: 1500,
            delay: 2000,
            elasticity: 0,
            delay: function(el, i) { return 600 + i * 250 },
            loop: false,
            complete: function(event){
              this.animatables.forEach((el) => {
                $(el.target).hide();
              })
            }
          })
          .add({
            targets: ['#about-circle_fill', '#about-circle_mask', '.about-circle_line_circle'],
            r: [190, 290],
            round: 1,
            elasticity: 0,
            delay: 0,
            duration: 1000,
            easing: 'easeOutCubic',
            loop: false,
            begin: function(){
              this.animatables.forEach((el) => {
                $(el.target).show();
              })
            },
          })
          .add({
            targets: '#about-mv .logo',
            opacity: [0, 1],
            // round: 1,
            elasticity: 0,
            delay: 500,
            duration: 800,
            begin: function(){
                $('#about-mv .arrow').addClass('active');
            },
            easing: 'easeOutCubic',
            loop: false
          })

    const aboutTrigger = new ScrollHelper(() => {
      const imageTop = $('.circle-body .image').offset().top;
      const threshold = $window.height() * 0.75;
      if(imageTop - threshold < $window.scrollTop()){
        tl.play();
        fadeText.play();
        aboutTrigger.destroy();
      }
    });

});

$(() => {

  const $body = $('body');
  const $window = $(window);
  // const preloader = new Preloader();

  $body.addClass('ready');


  // -----------------------------------------------------------------------------
  // HEADER
  // languages menu
  const $lang = $('#menu-languages');
  $lang.hover((event) => {
    $lang.toggleClass('active', true)
  }, () => {
    $lang.toggleClass('active', false)
  });
  // $lang.find('a').each((index, el) => {
  //   const $el = $(el);
  //   $(el).on('mouseleave', (event) => {
  //     $(event.currentTarget).removeClass('blink');
  //   })
  //   $(el).on('click', (event) => {
  //     $(event.currentTarget).addClass('blink')
  //     location.href = event.currentTarget.href;
  //     setTimeout(() => {
  //     }, 300)
  //     event.preventDefault();
  //     event.stopPropagation();
  //     return false;
  //   });
  // });

  // menu
  $('.js_open-menu').on('click', () => {
    $body.addClass('menu-active');
  });
  $('.js_toggle-menu').on('click', () => {
    $body.toggleClass('menu-active');
  });
  $('.js_close-menu').on('click', () => {
    $body.removeClass('menu-active');
  });

  $('.js_toggle-submenu').on('click', e => {
    const el = e.currentTarget
    const target_id = el.getAttribute('data-toggle-target')
    const $target = $(target_id)
    el.classList.toggle('active')
    $target.slideToggle()
  })

  // bodyclass(scrolled)
  let windowHeight = window.innerHeight;
  const resizeHelper = new ResizeHelper(() => {
    windowHeight = window.innerHeight;
  })
  const stickyHeader = new ScrollHelper(function(){
    $body.toggleClass('scrolled', $(window).scrollTop() >= windowHeight);
  });

  // scroll label
  const scrollSign = new ScrollHelper(() => {
    if($(window).scrollTop() >= 50){
      scrollSign.destroy();
      $('#scroll').css('opacity', 0)
    }
  });

  // -----------------------------------------------------------------------------
  // INDEX

  // parallax

  if($('#index-about').length){
    const device_aboutphoto = new Device({
      breakpoint: BREAK_POINT
    });
    const rellax_aboutphoto = new Rellax('#index-about .about-photo', {
      center: true

    });
    device_aboutphoto.on('sp', (e) => {
      rellax_aboutphoto.options.speed = 0.5;
      rellax_aboutphoto.refresh();
    });
    device_aboutphoto.on('pc', (e) => {
      rellax_aboutphoto.options.speed = 1;
      rellax_aboutphoto.refresh();
    });
  }

  if($('.rellax').length){
    const rellax = new Rellax('.rellax', {
    });
  }

  const $aboutBlocks = $('.about-block');
  if($aboutBlocks.length){
    const rellaxBackgrounds = [];
    const rellaxImages = [];

    $aboutBlocks.each((index, el) => {
      const background = el.querySelector('.block-bg');
      const image = el.querySelector('.block-image');

      rellaxBackgrounds.push(
        new Rellax(background, {
          speed: -1.2,
          center: true,
          // wrapper: el,
          round: true,
          vertical: true,
          horizontal: false
        })
      );
      rellaxImages.push(
        new Rellax(image, {
          // speed: (function(){
          //   return $window.width() < BREAK_POINT ? 0.3 : 2;
          // })(),
          center: true,
          // wrapper: el,
          round: true,
          vertical: true,
          horizontal: false
        })
      );
    });


    const device = new Device({
      breakpoint: BREAK_POINT
    });
    device.on('sp', (e) => {
      rellaxBackgrounds.forEach(rellax => {
        rellax.options.speed = -0.75;
        rellax.refresh();
      });
      rellaxImages.forEach(rellax => {
        rellax.options.speed = 0.3;
        rellax.refresh();
      });
    });
    device.on('pc', (e) => {
      rellaxBackgrounds.forEach(rellax => {
        rellax.options.speed = -1.2;
        rellax.refresh();
      });
      rellaxImages.forEach(rellax => {
        rellax.options.speed = 2;
        rellax.refresh();
      });
    });
  }


  // -----------------------------------------------------------------------------
  // COMMON
  $('.slidee')
    // duplicate
    // .html(function(){
    //   var html = $('.slidee').html();
    //   return html + html;
    // })
    .slick({
      dots: false,
      draggable: false,
      infinite: true,
      variableWidth: true,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 0,
      pauseOnFocus: false,
      pauseOnHover: false,
      speed: 12000,
      centerMode: false,
      cssEase: 'linear',


      // paginate
      arrows: false,
      // nextArrow: '<button type="button" class="slick-next"></button>',
      // prevArrow: '<button type="button" class="slick-prev"></button>',
    });



  // scroll
  const smoothScroll = new SmoothScroll({
    breakpoint: BREAK_POINT,
    offset: {
      pc: 120,
      sp: 188
    }
  });
  smoothScroll.begin = () => {
    if($window.width() < BREAK_POINT){
      $body.removeClass('menu-active');
    }
  }

  // inview
  $('.inview').on('inview', (event, isInView) => {
    if(isInView){
      $(event.currentTarget).addClass('is-inview');
    }
  });


  // -----------------------------------------------------------------------------
  // PAGES

  // QA
  $('#qa').find('.qa-item').each(function(index, el){
    const $item = $(el);
    $item.find('.qa-title').on('click', () => {
      $item.toggleClass('active');
      $item.find('.qa-body').slideToggle('fast');
      return false;
    })
  });

  // Privacy
  $('.scrollbar-inner').scrollbar();



  objectFitPolyfill();


});
